import { debounce } from "lodash";
import { vueApp } from "./main";

export const addToast = (args: any) => {
  return vueApp.$toast.add(args);
};

export const addSuccessToast = (msg: string) => {
  return vueApp.$toast.add({
    severity: "success",
    summary: "Success",
    detail: msg,
    life: 5000,
  });
};

export const addWarnToast = (msg: string) => {
  return vueApp.$toast.add({
    severity: "warn",
    summary: "Warning",
    detail: msg,
    life: 5000,
  });
};

export const debouncedAddWarnToast = debounce(addWarnToast, 600);

export const addErrorToast = (msg: string) => {
  return vueApp.$toast.add({
    severity: "error",
    summary: "Error",
    detail: msg,
    life: 5000,
  });
};

export const primeVueConfirm = ({
  title,
  text,
  icon,
}: {
  title?: string;
  text: string;
  icon?: string;
}) => {
  return new Promise<{ isOk: boolean }>((resolve) => {
    vueApp.$confirm.require({
      message: text,
      header: title || "Confirmation",
      icon: icon || "pi pi-exclamation-triangle",
      accept: () => resolve({ isOk: true }),
      reject: () => resolve({ isOk: false }),
    });
  });
};
