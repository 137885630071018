import gql from "graphql-tag";

export const fragments = {
  FullUser: gql`
    fragment FullUser on User {
      userId
      managerId
      uplineId
      lineageIds
      username
      nickname
      roleName
      creditGiven
      mobile1
      mobile2
      mobile3

      createdAt

      autoResetBalance
      createAgent
      deleteBet
      createPayment
      readStatement
      placeBets
      remarks

      intakeType

      ddEnabled


      ddManagementExpensesMode
      ddManagementExpenses
      ddManagementExpensesManagerTicketInPercent

      ddShareCompanyExpenses
      ddTicketRebate
      ddCompanyPl
      ddPlaceoutPl
      ddUplineIntakePl
      ddCanIntake
      ddIntake
      ddIntakeBig
      ddIntakeSmall
      ddIntakeMethod
      ddIntakeTax
      ddDownlineIntake
      ddFirstPrizeCommission
      ddSecondPrizeCommission
      ddThirdPrizeCommission
      ddStarterPrizeCommission
      ddConsolationPrizeCommission

      ttEnabled

      ttManagementExpensesMode
      ttManagementExpenses
      ttManagementExpensesManagerTicketInPercent

      ttShareCompanyExpenses
      ttDownlineIntake

      ttD1TicketRebate
      ttD2TicketRebate
      ttD3TicketRebate
      ttD4TicketRebate
      ttD1Payout
      ttD2Payout
      ttD3Payout
      ttD4Payout
      ttD1CompanyPl
      ttD2CompanyPl
      ttD3CompanyPl
      ttD4CompanyPl
      ttD1PlaceoutPl
      ttD2PlaceoutPl
      ttD3PlaceoutPl
      ttD4PlaceoutPl
      ttD1UplinePl
      ttD2UplinePl
      ttD3UplinePl
      ttD4UplinePl
      ttIntake
      ttCanIntake
      ttIntakeD1
      ttIntakeD2
      ttIntakeD3
      ttIntakeD4
      ttIntakeTax

      # downlines {
      #   userId
      #   managerId
      #   uplineId
      #   username
      #   nickname
      #   roleName
      #   creditGiven
      #   mobile
      #   status

      #   autoResetBalance
      #   createAgent
      #   deleteBet
      #   createPayment
      #   readStatement
      #   placeBets
      #   remarks

      #   creditSummary {
      #     creditGiven
      #     creditUsed
      #     creditBalance
      #   }
      # }

      status
    }
  `,
  CreditSummary: gql`
    fragment CreditSummary on User {
      creditSummary {
        creditGiven
        creditUnpaid
        creditGivenToDownlines
        betsInProgress
        creditUsed
        creditBalance
      }
    }
  `,
  MinimalUser: gql`
    fragment MinimalUser on User {
      userId
      managerId
      uplineId
      lineageIds
      username
      nickname
      roleName

      intakeType

      ddEnabled
      ddTicketRebate
      ttD1TicketRebate
      ttD2TicketRebate
      ttD3TicketRebate
      ttD4TicketRebate
    }
  `,
  DDPage: gql`
    fragment DDPage on DDPage {
      id
      name
      releaseDate
      createdAt
      enteredFrom
      enteredById
      userId
      username

      # releases {
      #   id
      #   releaseDate
      # }
      calculation {
        bigCost
        smallCost
        bigCount
        smallCount
        rawCost
      }
    }
  `,
  DDPageSimple: gql`
    fragment DDPageSimple on DDPage {
      id
      name
      createdAt
      enteredFrom
      enteredById
      userId
      username
    }
  `,
  FullDDPage: gql`
    fragment FullDDPage on DDPage {
      id
      name
      releaseDate
      createdAt
      enteredFrom
      enteredById
      userId
      username

      # releases {
      #   id
      #   releaseDate
      # }
      calculation {
        bigCost
        smallCost
        bigCount
        smallCount
        rawCost
      }
      entries
    }
  `,
  SummaryDDPage: gql`
    fragment SummaryDDPage on DDPage {
      id
      name
      releaseDate
      createdAt
      enteredFrom
      enteredById
      userId
      username

      # releases {
      #   id
      #   releaseDate
      # }
      calculation {
        bigCost
        smallCost
        bigCount
        smallCount
        rawCost
      }
    }
  `,
  TTPageSimple: gql`
    fragment TTPageSimple on TTPage {
      id
      name
      createdAt
      enteredFrom
      enteredById
      userId
      username
    }
  `,
  FullTTPage: gql`
    fragment FullTTPage on TTPage {
      id
      name
      releaseDate
      createdAt
      enteredFrom
      enteredById
      userId
      username

      calculation {
        unit
      }
      entries
    }
  `,
  SummaryTTPage: gql`
    fragment SummaryTTPage on TTPage {
      id
      name
      releaseDate
      createdAt
      enteredFrom
      enteredById
      userId
      username

      calculation {
        unit
      }
    }
  `,
  FullStoredDDPage: gql`
    fragment FullStoredDDPage on StoredDDPage {
      id
      name

      createdAt
      storedPageType
      enteredById

      uploadFileName
      uploadFileSize

      entries
    }
  `,
  FullStoredTTPage: gql`
    fragment FullStoredTTPage on StoredTTPage {
      id
      name

      createdAt
      storedPageType
      enteredById

      uploadFileName
      uploadFileSize

      entries
    }
  `,
  FullDeletedDDPage: gql`
    fragment FullDeletedDDPage on DeletedDDPage {
      id
      name
      userId
      username
      createdAt
      enteredFrom
      enteredById
      deletedById
      deletedAt
      calculation {
        bigCost
        smallCost
        bigCount
        smallCount
        rawCost
      }
      # entries {
      #   number
      #   day
      #   big
      #   small
      #   mode
      #   calculation {
      #     bigCost
      #     smallCost
      #     bigCount
      #     smallCount
      #     rawCost
      #   }
      #   entriesRaw {
      #     number
      #     big
      #     small
      #   }
      # }
    }
  `,
  DeletedDDPageWithEntries: gql`
    fragment DeletedDDPageWithEntries on DeletedDDPage {
      id
      name
      userId
      username
      createdAt
      enteredFrom
      enteredById
      deletedById
      deletedAt
      entries
      # entries {
      #   number
      #   day
      #   big
      #   small
      #   mode
      #   calculation {
      #     bigCost
      #     smallCost
      #     bigCount
      #     smallCount
      #     rawCost
      #   }
      #   entriesRaw {
      #     number
      #     big
      #     small
      #   }
      # }
    }
  `,
  DeletedTTPageWithEntries: gql`
    fragment DeletedTTPageWithEntries on DeletedTTPage {
      id
      name
      userId
      username
      createdAt
      enteredFrom
      enteredById
      deletedById
      deletedAt
      entries
    }
  `,
  FullDeletedTTPage: gql`
    fragment FullDeletedTTPage on DeletedTTPage {
      id
      name
      userId
      username
      createdAt
      enteredFrom
      enteredById
      deletedById
      deletedAt
      entries
      calculation {
        unit
      }
    }
  `,
  Release: gql`
    fragment Release on Release {
      releaseDate
      releaseDay
      appMode
      windowOpenAt
      windowCloseAt
      windowIsOpen
      isOver

      ttMaximumStake

      locked
      fixedBetDone
      outsetLinkingDone
      resultsComplete
      reportsGenerated
      outsetClaimUpdated

      fixedExpenses
      variableExpenses

      companyIntakeBig
      companyIntakeSmall
      companyIntakeD1
      companyIntakeD2
      companyIntakeD3
      companyIntakeD4
    }
  `,
  Payment: gql`
    fragment Payment on Payment {
      id
      releaseDate
      paymentType
      remark
      payorUserId
      payeeUserId
      amount
      createdByUserId
      createdAt
    }
  `,
};
